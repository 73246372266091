import * as React from "react"
import "../styles/layout.css"
import { ExternalLinkIcon, XCircleIcon } from "@heroicons/react/outline"
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const slideup = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-2px, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Footer = () => {
  return (
    <div className="flex flex-col w-7/12 my-20 mx-auto text-center">
    <p className="text-gray-400">Copyright &copy; 2021 Noodles. All Rights Reserved.</p>
    </div>
  )
}

export default Footer