import * as React from "react"
import "../styles/layout.css"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { ArrowDownIcon } from '@heroicons/react/outline'
import { Helmet } from "react-helmet"
import Avatar from "../images/avatar.png"
import Favicon from "../images/favicon.png"

const Header = () => {
  return (
    <div>
      <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Noodles — web development and design</title>
          <link rel="canonical" href="https://noodles.cool" />
          <link rel="icon" href={Favicon} />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="theme-color" content="#FC9223" />
          <meta name="keywords" content="noodles, noodles1" />
          <meta name="description" content="A frontend web developer and designer." />
          <meta name="twitter:title" content="Noodles — web development and design" />
          <meta name="twitter:description" content="A frontend web developer and designer." />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Noodles" />
          <meta property="og:title" content="Noodles — web development and design" />
          <meta property="og:description" content="A frontend web developer and designer." />
          <meta property="og:url" content="https://noodles.cool" />
          <meta property="og:image" content="https://i.file.glass/hi95i.png" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
        </Helmet>
    <div className="flex mx-5 mt-5 font-bold justify-between">
      <Link to="/">
      <h1 className="text-white">Noodles</h1>
      </Link>
      <div className="flex space-x-1 mb-3 z-10">
        <a href="https://github.com/goodnightnoodles" aria-label="Fileglass GitHub Repository Link" target="_blank" rel="noreferrer">
          <button className="group flex h-8 w-8 items-center justify-center bg-gray-800 hover:bg-white rounded-full transition duration-200 ease-in-out" aria-label="Noodles' GitHub Profile">
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" className="h-5 w-5 text-white group-hover:text-black duration-200 ease-in-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
            </button>
            </a>
             <a href="https://discord.com/users/177350938854555648" aria-label="Fileglass GitHub Repository Link" target="_blank" rel="noreferrer">
             <button className="flex h-8 w-8 items-center justify-center bg-gray-800 hover:bg-indigo-500 rounded-full transition duration-200 ease-in-out" aria-label="Noodles' Discord Account">
               <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" className="h-5 w-5 text-white" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>
               </button>
             </a>
             <a href="mailto:josh@file.glass" aria-label="Fileglass GitHub Repository Link" target="_blank" rel="noreferrer">
             <button className="group flex h-8 w-8 items-center justify-center bg-gray-800 hover:bg-white rounded-full transition duration-200 ease-in-out" aria-label="Noodles' GitHub Profile">
               <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" className="h-4 w-4 text-white group-hover:text-black duration-200 ease-in-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
               </button>
             </a>
              </div>
    </div>
    <motion.div animate={{ y: -25, opacity: 1 }} initial={{ opacity: 0 }} className="flex h-screen text-center">
      <div className="flex flex-col m-auto items-center pointer-events-none select-none">
        <div className="flex bg-gradient-to-b from-yellow-500 to-yellow-700 h-40 w-40 sm:h-60 sm:w-60 mb-3 items-center justify-center rounded-full">
    <img className="rounded-full h-36 w-36 sm:h-52 sm:w-52" src={Avatar} width="100%" height="100%" alt="Noodles Avatar" />
    </div>
    <p className="text-7xl sm:text-8xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-gradient-1 to-gradient-2">Hi there,</p>
    <p className="text-5xl sm:text-6xl text-white font-bold">I'm Noodles.</p>
    <div className="mt-3 text-gray-300 flex flex-col items-center">
      <p className="uppercase text-sm">Scroll Down</p>
      <ArrowDownIcon className="animate-bounce mt-2 h-5 w-5" />
    </div>
    </div>
    </motion.div>
    </div>
  )
}

export default Header