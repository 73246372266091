import * as React from "react"
import "../styles/layout.css"
import { ExternalLinkIcon, XCircleIcon } from "@heroicons/react/outline"
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const slideup = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-2px, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Projects = () => {
  return (
      <div className="flex flex-col w-9/12 space-y-4 mx-auto text-center">
    <div className="space-y-2">
    <Reveal keyframes={slideup} triggerOnce={true} cascade={true} damping={0.03}> 
      <h1 className="text-3xl text-white font-semibold">Projects</h1>
      <p className="text-gray-400">My current and former projects.</p>
      </Reveal>
    </div>
    <Reveal keyframes={slideup} triggerOnce={true} cascade={true} damping={0.03}> 
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div className="bg-gradient-to-br md:bg-gradient-to-bl from-gray-800 to-gray-900 transform duration-500 hover:scale-105 rounded-xl py-10 max-h-lg w-full">
        <div className="mx-10 space-y-5">
            <div className="flex mx-auto justify-center items-center space-x-2">
    <h2 className="text-xl text-gray-200">Fileglass</h2>
    <span className="bg-indigo-700 text-white text-xs uppercase rounded-full py-1 px-2">Current</span>
    </div>
        <p className="text-gray-400 text-sm">Fileglass is a blazing fast, privacy-centric and free file host with focus on speed, reliability and security. With support for ShareX and more.</p>
        <div className="flex mx-auto justify-center">
        <a href="https://file.glass" target="_blank" rel="noreferrer">
        <button className="flex bg-gray-700 hover:bg-gray-600 duration-200 ease-in-out text-white mt-3 py-1.5 px-3 rounded-full text-sm"><ExternalLinkIcon className="h-5 w-5 mr-1 text-gray-400"/>Fileglass</button>
        </a>
        </div>
    </div>
    </div>
    <div className="bg-gradient-to-br md:bg-gradient-to-br from-gray-800 to-gray-900 transform duration-500 hover:scale-105 rounded-xl py-10 max-h-lg w-full">
        <div className="mx-10 space-y-5">
            <div className="flex mx-auto justify-center items-center space-x-2">
    <h2 className="text-xl text-gray-200">Noodles.wtf</h2>
    <span className="bg-gray-700 text-white text-xs uppercase rounded-full py-1 px-2">Former</span>
    </div>
        <p className="text-gray-400 text-sm">Noodles.wtf was a private, web-based, media hosting platform, it launched in August 2020, and was later superseded by Fileglass in February 2021.</p>
        <div className="flex mx-auto justify-center">
        <button className="flex bg-gray-700 text-white mt-3 py-1.5 px-3 rounded-full text-sm"><XCircleIcon className="h-5 w-5 mr-1 text-gray-400"/>Link Unavailable</button>
        </div>
    </div>
    </div>
    </div>
    </Reveal>
    </div>
  )
}

export default Projects