import * as React from "react"
import "../styles/layout.css"
import { motion } from "framer-motion"

import Header from "../components/Header"
import Footer from "../components/Footer"
import About from "../components/About"
import Projects from "../components/Projects"

const Layout = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <body className="flex flex-col min-h-screen">
  <header>
  <Header />
  </header>
  <main className="flex-grow space-y-20">
    <About />
    <Projects />
  </main>
  <footer>
  <Footer />
  </footer>
</body>
    </motion.div>
  )
}

export default Layout